import {Profile} from './profile.js';
import { PlayServer } from './play-server.js';

import {Login} from './login.js'
import { UI } from './ui.js';
import { Payments } from './payments/payment.js';
import { CollectionsBookmarkOutlined, PictureAsPdf, SettingsRemoteRounded, UndoRounded } from '@mui/icons-material';
import { createConnector } from './stream/connector-factory.js';
import { render } from 'react-dom';
import { Ads } from './ads/ads.js';
import { last } from 'lodash';

var cachedevts = [];
var reload_on_activate = false;
var video_filter_support = false;

var session_id="";
var session_start = new Date().getTime() / 1000;
var reload_triggered = false;
export var fullscreendone = false;
var rejoin_session = false;
var store_logs = false;
var secondsLoading = new Date().getTime() / 1000;
var startMusicOnClick = false;
var shownClickToStart = false;
var screenshot_onload = false;
var first_load = 0;

var dragstartx = 0;
var dragstarty = 0;
var dragged = false;
var ismousedown = false;
var received_oob = false;
var session_times = {};
var last_stats = null;

var lock_spinner = false;

var finish_spawned = false;

var overtime = false;

var additional_timeout = 0;

var timeout_id = -1;
var timeout_id2 = -1;
var holded_long = 0;

var secondsFrozen = new Date().getTime() / 1000;
var startedAt = new Date().getTime() / 1000;

function isDisableAnimations()
{
    if(parser.getDevice().type == "smarttv") {
        return true;
    }

    return false;
}

function getPlayingTime() {
    return Math.floor(new Date().getTime() / 1000 - startedAt);
}

var wasmuted = false;
var lastSaturation = 0;

var logs_collected = [];

function get_time_now()
{
    var now = new Date();
    return now.getFullYear() + "." + (now.getMonth() + 1) + "." + now.getDate() + " " + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
}

function loaderEnabled() {
    var dev = parser.getDevice().type;
    
    if(window.innerHeight < window.innerWidth &&
        ( dev == "mobile" || dev =="tablet" )) {
        return false;
    }

    return true;
}

function logcollect(tag, data)
{
    if(typeof data != "string" && typeof data != "number") {
        data = JSON.stringify(data);
    }
    // get date and time as formatted string
    var now = new Date();

    logs_collected.push({"tag": tag, text: "["+get_time_now()+"] " + data});
}

function reportLogs()
{
    // generate 6 number bug id
    var bug_id = Math.floor(Math.random() * 10000);

    window.ui.showToast("PIN: " + bug_id);

    var play_now = "";

    try{
        play_now = window.play_server.getServer(current_game);
    }catch(e){}

    mylogi({'time_now': get_time_now(),
    'login_server': serverurl_login,
    'pay_server': serverurl_pay,
    'play_server': play_now});

    $.ajax({
        url:serverurl_logs+"log/"+bug_id+"/",
        type:"POST",
        data:JSON.stringify({'time_now': get_time_now(),
                                'login_server': serverurl_login,
                                'pay_server': serverurl_pay,
                                'play_server': play_now,
                                'logs': logs_collected}),
        contentType:"application/json; charset=utf-8",
        dataType:"json",
        success: function(data) {
            logs_collected = [];
        }
    });
}

export function mylog(data)
{
    if(window.log != null) {
        if(typeof data == "string" || typeof data == "number") {
            window.log.log("verbose", data);
        }else{
            window.log.log("verbose", JSON.stringify(data));
        }
    }

    logcollect("verbose", data);
}

export function mylogi(data)
{
    if(window.log != null) {
        if(typeof data == "string" || typeof data == "number") {
            window.log.log("info", data);
        }else{
            window.log.log("info", JSON.stringify(data));
        }
    }

    logcollect("info", data);
}

export function mylogd(data)
{
    if(window.log != null) {
        if(typeof data == "string" || typeof data == "number") {
            window.log.log("debug", data);
        }else{
            window.log.log("debug", JSON.stringify(data));
        }
    }

    logcollect("debug", data);
}

export function myloge(data)
{
    if(window.log != null) {
        if(typeof data == "string" || typeof data == "number") {
            window.log.log("error", data);
        }else{
            window.log.log("error", JSON.stringify(data));
        }
    }

    logcollect("error", data);
}

export function mylogr(data)
{
    if(window.log != null) {
        if(typeof data == "string" || typeof data == "number") {
            window.log.log("request", data);
        }else{
            window.log.log("request", JSON.stringify(data));
        }
    }

    logcollect("request", data);
}

window.mylog = mylog;
window.mylogi = mylogi;
window.mylogd = mylogd;
window.myloge = myloge;
window.mylogr = mylogr;

function getProfileID()
{
    if(window.profile == null) {
        return null;
    }
    return window.profile._profile_id;
}

function isvertical(game_id)
{
    for(var i=0; i<names.length; i++)
    {
        if(names[i]['id'] == game_id) {
            return names[i]['vertical'] == 1;
        }
    }
    return false;
}

function isHorizontalNow() {
    return window.innerHeight > window.innerWidth;
}

function gameSupportsRotate(game_id) {
    return game_id == special_game;
}

function canRotate(game_id)
{
    var dev = parser.getDevice().type;
    if( dev == "mobile" || dev =="tablet" ) {
        return true;
    }
    return false;
}

window.serverTime = Math.floor(Date.now() / 1000);
function parsePlay(data) {
    window.profile.versionNow(data['version']);
    window.serverTime = data['ts'];
}

export function send_ev(eventtype, params, force=false, queue=false)
{
    if(queue == false) {
        mylog({'event': eventtype, 'attributes': params});
    }

    if(getProfileID() == null && force == false) {
        cachedevts.push({"eventtype": eventtype, "params": params});
    }
    else{
        params['fid'] = getProfileID();
        params['session_id'] = session_id;

        try{
            params['session_num'] = getcounter("session");
        }catch(e) {

        }

        params['game'] = current_game;
        params['ts'] = new Date().getTime();
        params['platform'] = parser.getOS().name + "/" + parser.getBrowser().name;

        mixpanel.track(eventtype, params);
        if (typeof gtag !== "undefined") { 
            gtag("event", eventtype, params);
        }
        $.ajax({
            url:serverurl_logs+"logevent/",
            type:"POST",
            data:JSON.stringify({'eventtype': eventtype, 'params': params}),
            contentType:"application/json; charset=utf-8",
            dataType:"json",
            success: function(){
            }
        });
    }
}

function tutorial_finish()
{
    if(getcounter("tutorial") != 1) {
        if (typeof gtag !== "undefined") { 
            gtag('tutorial_finish');
        }
        
        if (typeof fbq !== "undefined") { 
            fbq('trackCustom', 'tutorial_finish');
        }

        send_ev("tutorial_finish", {});

        if(parser.getDevice().type != "smarttv") {
            setTimeout(() => {
                showBookmark();
            }, 10000);
        }

        setcounter("tutorial", 1, null, true);
        $("#footer-zoom").css({"display": "inline-block"});

        PubSub.publish("game.tutorial");
    }
}

export function send_click()
{
    var clicks = getcounter("click");
    send_ev("click", {"num": clicks, "session_time": new Date().getTime() / 1000 - session_start});
}

function set_favicon(url) {
    var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
}

var log_addr = window.location.search.substring(1);
var log_reff = document.referrer;

if(window.location.hash) {
    log_addr += window.location.hash;
}
send_ev('log_params', {'addr': log_addr, 'reff': log_reff});

function sendCachedEvents()
{
    for(var i=0; i<cachedevts.length; i++)
    {
        send_ev(cachedevts[i]["eventtype"], cachedevts[i]["params"], false, true);
    }
    cachedevts = [];
}

function fade_sound_in(audio)
{
    let playAttempt = setInterval(() => {
        if(audio.volume >= 1.0) {
            clearInterval(playAttempt);
            audio.volume = 1.0;
        }else{
            try{
                audio.volume += 0.005;
            }catch(e) {
                audio.volume = 1.0;
            }

            if(audio.volume >= 1.0) {
                clearInterval(playAttempt);
                audio.volume = 1.0;
            }
        }

        var state = getcounter('muted', 'all');
        if(state == 1) {
            clearInterval(playAttempt);
            audio.volume = 0.0;

            if(parser.getOS().name == "iOS" || parser.getBrowser().name == "Safari") {
                audio.muted = true;
            }
        }
    }, 10);
}

/////////////////////////////////////////////////////////////
// animations
export function fly_from(from_pos, to, time)
{
    if(isDisableAnimations()) {
        return null;
    }

    var to_elem = $(to);

    if( to_elem == null) {
        return null;
    }

    var pos_from = from_pos;
    var pos_to = to_elem.offset();

    if(pos_from == null || pos_to == null) {
        return null;
    }

    var fly = to_elem.clone(false).appendTo(".animations-top");

    var id = 'anim-'+to_elem.attr('id') + "-" + to_elem.attr('id');
    fly.attr('id', id);
    fly.css({"visibility":"visible"});

    fly.removeClass("bottombounce");
    fly.off("mouseenter mouseleave");
    
    fly.css({position : "fixed", top: pos_from.top, left: pos_from.left, "z-index": 100000})
        .animate({top: pos_to.top, left: pos_to.left}, time, function(){
            $( "#" + id ).remove();
        }); 

    return fly;
}

function fly(from, to, takepos=null, showafter=true, additional_y=0)
{
    var $from_elem = $(from);
    var $to_elem = $(to);

    if($from_elem == null || $to_elem == null) {
        return null;
    }

    var pos_from = $from_elem.offset();
    var pos_to = $to_elem.offset();

    if(takepos != null) {
        //pos_to = $(takepos).offset();
    }
    if(pos_from == null || pos_to == null) {
        return null;
    }

    console.log(from, to, pos_from, pos_to);

    var $cur_vis = $from_elem.css("visibility");
    $from_elem.css({"visibility":"hidden"});

    var addx = 325;
    var dev = parser.getDevice().type;
    if( dev == "mobile" || dev =="tablet" ) {
        addx = 0;
    }

    if(isDisableAnimations() == false) {
        var $fly = $from_elem.clone(false).appendTo(".animations-top");

        var id = 'anim-'+$from_elem.attr('id') + "-" + $to_elem.attr('id');
        $fly.attr('id', id);
        $fly.css({"visibility":"visible", "border-radius": "0px", "object-fit": "contain", "background-color": "rgba(0,0,0,0)", "box-shadow": "none", "opacity": 1.0, "filter": $to_elem.css("filter")});
        $fly.removeClass("bottombounce");
        
        $fly.off("mouseenter mouseleave");

        console.log(pos_to.top);
        console.log(pos_to);
        
        $fly.css({position : "absolute", top: pos_from.top, left: pos_from.left, "opacity": 1.0, "max-width": "100vw", "max-height": "100vh"})
            .animate({top: pos_to.top+additional_y - 5, left: pos_to.left, width: ($to_elem.width() + addx) + "px", height: ($to_elem.height() + 33) + "px"}, 1000, function(){
                setTimeout(function() {
                    $( "#" + id ).remove();
                }, 250);

                if(showafter) {
                    $from_elem.css({"visibility": $cur_vis});
                }
            }); 
    }else{
        if(showafter) {
            $from_elem.css({"visibility": $cur_vis});
        }
    }

    return $fly;
}

var firstoffset = 32;

function tryToShowPWA()
{
    if(current_stream != null && current_stream.isplaystarted() == true) {
        window.ui.showiOSPWA();
    }else{
        setTimeout(() => {
            window.ui.showiOSPWA();
        }, 1000);
    }
}

var animating_out = null;

function animate_out(elem, takepos=null, default_delay=1000)
{
    var restore_center = false;
    if($(elem).hasClass("rotatecenter")) {
        restore_center = true;
        $(elem).removeClass("rotatecenter");
    }

    var fly = $(elem).clone(true).appendTo(".animations-top");
    animating_out = fly;

    var pos_from = $(elem).offset();
    if(takepos != null) {
        pos_from = $(takepos).offset();
    }
    var dev = parser.getDevice().type;

    var left = pos_from.left;
    if( dev == "mobile" || dev =="tablet" ) {
        if( window.innerHeight < window.innerWidth ) {
            left = 0;
        }
    }

    var id = 'anim-out-' + $(elem).attr('id') + Date.now();
    fly.attr('id', id);
    
    if(sb_mode == 1) {
        setTimeout(() => {
            $(elem).hide();
        }, 500 + additional_timeout);
    }
    

    var ew = $(elem).width();
    var eh = $(elem).height();

    fly.css({position : "absolute", top: pos_from.top, left: left + "px", width:ew + "px", height:eh + "px", opacity: 1.0});

    if(gameSupportsRotate(current_game) == false && canRotate(current_game) && isvertical(current_game) == false && isHorizontalNow()) {
        fly.addClass("rotatecenter");
    }

    // if doesnt have centering-div
    /*if(fly.hasClass("centering-div") == false) {
        fly.addClass("centering-div");
    }*/

    setTimeout(() => {
        var addx = offsetalways;
        var offsetmore = 32;
        if( dev == "mobile" || dev =="tablet" ) {
            addx = 0;

            if( window.innerHeight < window.innerWidth ) {
                offsetmore = 0;
            }
        }

        if(sb_mode == 1) {
            offsetmore = 0;
        }

        fly.css({position : "absolute", top: pos_from.top, left: left, width: (ew + addx) + "px", height: (eh + offsetmore) + "px",
                  display: "block", visibility: "visible"})
            .animate({opacity: 0}, isDisableAnimations()?0:1000, function(){
                firstoffset = 0;
                firstoffsetx = 0;
                $( "#" + id ).remove();
                game_loading = false;
                
                $("#footer-fs").css({'background-color': '#fff'});
                $("#footer-fs2").css({'background-color': '#fff'});
                
                $("#footer-fs").css({'pointer-events': 'auto'});
                $("#footer-fs2").css({'pointer-events': 'auto'});

                $("#footer-fs").css({'cursor': 'pointer'});
                $("#footer-fs2").css({'cursor': 'pointer'});

                PubSub.publish("game.loaded");
                
                if(parser.getOS().name == "iOS" && running_pwa == false && sb_mode == 1) {
                    if(parser.getBrowser().name == "Mobile Safari" || parser.getBrowser().name == "Safari") {
                        var delay = 20000;

                        var dev = parser.getDevice().type;
                        if( dev == "mobile"  ) {
                            delay = 1000;
                        }

                        setTimeout( () => {
                            tryToShowPWA();
                        }, delay );
                    }
                }

                if(game_changes <= 1) {
                    do_bottom_animation(1000);
                }
            });
    }, default_delay + additional_timeout);

    if(restore_center) {
        $(elem).addClass("rotatecenter");
    }

    return fly;
}

function fade_into(elem, newsrc)
{
    if(isDisableAnimations()) {
        $("#mainback").attr("src", newsrc);
        return;
    }

    if($(elem).attr("src") == newsrc) {
        return;
    }
    $(elem).animate({opacity: 0}, 500, function(){
        $("#mainback").attr("src", newsrc);
        $(elem).animate({opacity: 1}, 500, function(){
        });
    });
}

function hideout_force(elem)
{
    var fly = $(elem).clone(true).appendTo(".animations");
    var pos_from = $(elem).offset();

    var id = 'anim-out2-' + $(elem).attr('id');
    fly.attr('id', id);
    fly.css({position : "absolute", top: pos_from.top, left: pos_from.left, width: $(elem).width() + "px", height: $(elem).height() + "px"});
    fly.css({"visibility":"visible"});

    fly.css({position : "absolute", top: pos_from.top, left: pos_from.left, width: $(elem).width() + "px", height: $(elem).height() + "px"})
        .animate({opacity: 0}, 1, function(){
            $( "#" + id ).remove();
        }); 
}

/////////////////////////////////////////////////////////////
// game switch logic
export var current_stream = null;
var current_game = null;
var last_game = null;

export function getCurrentGame() {
    return current_game;
}

class BestGamesConnector {
    constructor(options) {
        this._options = options
    }

    async connect() {
        return {
            websocket: this._options["session"]["url"],
            stunServers: this._options["session"]["stun_servers"]
        };
    };

    // no-op
    disconnect() {}
}

function thumbPostfix(game_id)
{
    if(window.innerHeight > window.innerWidth && canRotate(game_id)) {
        return "";//"vert"
    }else{
        return "";
    }
}

function sendRestoreToGame(restore_id_on_load)
{
//    return ;//TMP REMOVE

    mylogi("Forcing to restore", restore_id_on_load);
    if(sb_mode == 1) {
        sendToGame({}, "restore_action", restore_id_on_load);
    }else{
        sendToGame({}, "restore", restore_id_on_load);
    }

    setTimeout(() => {
        if(received_oob == false) {
            sendRestoreToGame(restore_id_on_load);
        }
    }, 1000);
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// messages
export function open_rul(url) {
    window.ui.showUrlPopup(url);

//    window.open(url, '_blank');
}

async function openURLWithMapping(data) {
    mylogi(data);
    console.log(data);

    // exit fullscreen
    if(window.Main.current_stream != null && document.fullscreenElement != null) {
        window.Main.current_stream.exitFullscreen();
    }
    if(data.includes("accounts")) {
        return;
    }

    if(data.startsWith("http") == false) {
        const mapping_response = await fetch(prefix + "f/url_mapping.json", {
            method: "GET"
        });
        const mapping_response_json = await mapping_response.json();

        var url = mapping_response_json[data];

        mylogi(mapping_response_json, "resolved url to", url, data);

        // if url is object
        if(url != null && typeof url == "object") {

            window.ui.showSelectPayment(() => {
                window.pay.do_payment(url['item'], "" + Math.random(), "url");
            }, url['url']);

        }else{
            if(url != null && url != undefined && url != 'undefined') {
                open_rul(url);
            }
        }
    }else{
        var url = data;

        if(url.includes("://market") && url.includes("iap=")) {
            const urlParams = new URLSearchParams(url);
            const last_token = urlParams.get('iap').split(".").pop();
            const play_id = urlParams.get('userId');

            //window.ui.showSelectPayment(() => {
            window.pay.pay_now(last_token, "" + Math.random(), JSON.stringify({user_id: play_id}), url);
            //}, url);
        }else{
            if(url != null && url != undefined && url != 'undefined') {
                open_rul(url);
            }
        }
    }
}

function handleMessageReceive(type, data) {
    received_oob = true;
    connected = true;

    console.log(type, data);

    //mylogi(type, data);

    if(type.startsWith("event_") == false) {
        //mylogi(type, data);
    }

    if(type == "activity") {
        /*if(data.includes(".BrowserActivity")) {
            window.play_server.refocusGame(window.Main.getCurrentGame());
        }*/
    }

    if(type == "keyboard") {
        //alert("keyboard");
        console.log("keyboard");

        if( current_game.includes("omGu") == false && current_game.includes("eofA") == false) {
            window.ui.showKeyboard();
        }
    }
    if(type == "signin") {
        console.log("signin");
        window.ui.showLogin();

       /* setTimeout(() => {
            window.play_server.restartGame(current_game)

            // resend ping in 2000ms
            setTimeout(() => {
                connected = false;

                sendPing();
            }, 2000);
        }, 3000);*/
    }
    
    let events = type.split("_");
    
    if(type == "load_ad") {
        var json = JSON.parse(data);
        window.ads.load_ad(json['type'], json['placement']);
    }
    if(type == "show_ad") {
        var json = JSON.parse(data);
        window.ads.show_ad(json['type'], json['placement']);
    }
    if(type == "save_id") {
        mylogi("Got internal save id: ", data);

        window.profile.saveId(getCurrentGame(), data);
    }
    if(type == "event_lobby") {
        var obj = JSON.parse(data);
        window.ui.showLobbyPopup(obj['id']);
    }
    if(type == "open_url") {
        if(data == "852083747") {
            mylogi("opening pp");

            window.open(fronturl + "/ppzone", '_blank');
        }else{
            if(data == "1309758646") {
                mylogi("opening tos");

                window.open(fronturl + "/toszone", '_blank');
            }else{
                openURLWithMapping(data);
            }
        }
    }
    if(type == "buy_inapp") {
        var obj = JSON.parse(data);
        send_ev("buy_inapp", obj);
        
        window.pay.pay_now(obj['productID'], obj['paymentID'], obj['developerPayload']);
    }
    if(type == "consume_inapp") {
        send_ev("consume_inapp", {'id': data});
        
        window.pay.consumeInapp(data);
    }
    if(type == "event_auth") {
        try {
            var data_json = JSON.parse(data);
            var accounts = JSON.parse(data_json['dsa_accounts']);
            var support_id = accounts['support_id'];

            if(support_id != "" && support_id != null) {                        
                window.profile.setItem("support_" + current_game, support_id);

                $("#support_id").html(support_id.toUpperCase());
                $("#support-url").attr("href", support_url+"?support=" + support_id.toUpperCase());
            }
        }catch(e) {

        }
        spinnerState("auth");
    }
    if(events[0] == "event") {
        let orig_name = events[1];
        for(let i=2; i<events.length; i++) {
            orig_name = orig_name + "_" + events[i];
        }

        data = JSON.parse(data);
    }
}

var connected = false;
var sending_queue = [];

function sendQueue() {
    for(var i=0; i<sending_queue.length; i++) {
        sendData2(sending_queue[i]);
    }
    sending_queue = [];
}

function sendData2(object_to_send) {
    mylogi("Sending", object_to_send);
    if(current_stream != null && connected) {
        current_stream.sendData("game", object_to_send);
    }else{
        sending_queue.push(object_to_send);
    }
}

function sendPing() {
    if(connected == false && current_stream != null) {
       // mylogi("sending ping");
        current_stream.sendData("game", '{"type": "connected"}');

        setTimeout(() => {
            sendPing();
        }, 100);
    }
}

function onIncomingMessage(message) {
    var json = JSON.parse(message);

    handleMessageReceive(json['type'], json['data']);
}

var leftTextPart = "";

function parseIncomingMessages(data) {
    var message = "";
    var inside_quotes = false;
    var brackets = 0;
    var datafull = leftTextPart + data;

    for(var i=0; i<datafull.length; i++) {
        message = message + datafull[i];

        if(inside_quotes == false && datafull[i] == "{") {
            brackets++;
        }
        if(inside_quotes == false && datafull[i] == "}") {
            brackets--;

            if(brackets == 0) {
                // message end
                onIncomingMessage(message);
                message = "";
            }
        }
        if(datafull[i] == "\"" && i >0 && datafull[i-1] != "\\") {
            inside_quotes = !inside_quotes;
        }
    }

    leftTextPart = message;
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

var last_lost_packet = 0;
var freeze_type_active = "event";
var muted_now = false;

var last_freeze = 0;
var freeze_count = 0;

function detectedFreeze(freezetype)
{
    mylogi("Detected freeze: " + freezetype + " | " + freeze_count + " | " + (new Date().getTime() / 1000 - last_freeze));

    video_filter_support = true;

    if(parser.getOS().name == "iOS" || parser.getBrowser().name == "Safari") {
        freeze_type_active = "lost";
    }

    if(new Date().getTime() / 1000 - last_freeze > 10.0) {
        last_freeze = new Date().getTime() / 1000;
        freeze_count = 0;
    }else{
        last_freeze = new Date().getTime() / 1000;

        if(freeze_count == 3) {
            last_freeze = 0;

            if(window.pay != undefined && window.pay != null && window.pay.isPaying() == false) {
                window.ui.showUnstableConnection();
            }
        }
    }

    if(freezetype == "lost")
    {
        last_lost_packet = new Date().getTime() / 1000;

        setTimeout(() => {
            if(new Date().getTime() / 1000 - last_lost_packet >= 2.4999) {
                detectedUnfreze("lost");
            }else{
                mylogi("Not sending unfreeze: ", new Date().getTime() / 1000 - last_lost_packet);
            }
        }, 2500);
    }
    if(freezetype == freeze_type_active)
    {
        secondsFrozen = new Date().getTime() / 1000;
        wasmuted = true;
        send_ev("frozen", {type: freezetype});
        document.body.style.cursor='wait';

        if(current_stream != null && muted_now == false) {
            current_stream.setInputEnabled(false);

            if(video_filter_support) {
                $({saturate: 100}).animate({saturate: 10}, {
                    duration: 1500,
                    easing: 'linear',
                    step: function () {
                        if(current_stream != null) {
                            if(current_stream.getInputEnabled() == false) {
                                lastSaturation = this.saturate;
                                if(current_stream != null && current_stream.getVideoID() != null) {
                                    $("#" + current_stream.getVideoID()).css({
                                    '-webkit-filter': 'saturate(' + this.saturate + '%)',
                                    'filter': 'saturate(' + this.saturate + '%)'
                                    });
                                }
                            }
                        }
                    }
                });
            }else{
                showBWImage(true);
            }
        }
        muted_now = true;
    }
}

function detectedUnfreze(freezetype)
{
    mylogi("Detected unfreeze: " + freezetype);

    if(freezetype == freeze_type_active)
    {
        muted_now = false;

        send_ev("unfrozen", {"seconds": ((new Date().getTime() / 1000) - secondsFrozen)});


        if(((new Date().getTime() / 1000) - secondsFrozen) > 1.5) {
            freeze_count = freeze_count + 1;
        }

        document.body.style.cursor='default';

        if(current_stream != null) {
            current_stream.setInputEnabled(true);

            if(video_filter_support) {
                $({saturate: lastSaturation}).animate({saturate: 100}, {
                    duration: 150,
                    easing: 'swing',
                    step: function () {
                        if(current_stream != null) {
                            if(current_stream.getVideoID() != null) {
                                $("#" + current_stream.getVideoID()).css({
                                '-webkit-filter': 'saturate(' + this.saturate + '%)',
                                'filter': 'saturate(' + this.saturate + '%)'
                                });
                            }
                        }
                    },
                    complete: function() {
                        if(current_stream != null) {
                            if(current_stream.getVideoID() != null) {
                                $("#" + current_stream.getVideoID()).css({
                                    '-webkit-filter': 'saturate(100%)',
                                    'filter': 'saturate(100%)'
                                });
                            }
                        }
                    }
                });
            }else{
                showBWImage(false);
            }
        }
    }
}

var controls_sent = 500;
function setControlsMode() {
   // return ; // check with proper manifest
    mylogi("Setting controls mode");
    
    if(parser.getDevice().type != "mobile" && parser.getDevice().type != "tablet" && parser.getDevice().type != "smarttv") {
        mylogd("Activating pc");
        window.Main.sendToGame(0, "set_mode");
        
        window.Main.sendToGame({}, "set_settings", "0");
    }else{
        mylogd("Activating mobile");
        window.Main.sendToGame(1, "set_mode");
        
        window.Main.sendToGame({}, "set_settings", "0");
    }

    /*controls_sent = controls_sent - 1;
    if(controls_sent > 0) {
        setTimeout(() => {
            setControlsMode();
        }, 1000);
    }*/
}

function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    var items = location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

var first_game = true;
function showFrom(data, game_id, restore_id_on_load = "", restore_continue = false)
{
    loadstep("showing from");

    if(!first_game) {
        fade_into("#mainback", $("#thumb-"+thumbPostfix(game_id)+game_id).attr("src"));
        first_game = false;
    }else{
        first_game = false;
    }

    session_id = data["session"]["session_id"];

    var render_type = "default";
    if('render' in data["session"]) {
        render_type = data["session"]['render']['type'];
    }

    mylogi("Using render as", render_type);

    loadChat();

    connected = false;

    let stream = createConnector(render_type, {
        connector: new BestGamesConnector(data),
        targetElement: "streaming-game",
        controls: {
            keyboard: true
        },
        emulateTouches: game_id.includes("CityB") || game_id.includes("stNFl") || game_id.includes("ignHo") || game_id.includes("vetFas") || game_id.includes("opTita") || game_id.includes("ingCla") || game_id.includes("kiSol") || game_id.includes("ueAss") || game_id.includes("onLo") || game_id.includes("inSt") || game_id.includes("rtni"),
        dataChannels: {
            "game": {
              callbacks: {
                close: () => {
                  mylogi('data channel is closed');
                },
                open: () => {
                  mylogi('data channel is open');

                  //if(sb_mode != 1) {
                  sendPing();
                  //}

                  var lobby_id = findGetParameter("joinLobby");
                  if(lobby_id != null) {
                    mylogi("Found lobby to join, will join in 3 secs", lobby_id);

                    setTimeout(() => {
                        window.Main.sendToGame({}, "join_lobby", lobby_id);
                    }, 3000);
                  }
                },
                error: (err) => {
                  mylogi(`error: ${err}`);
                },
                message: (data) => {
                  parseIncomingMessages(data);

                  sendQueue();
                }
              }
            }
        },
        callbacks: {
            ready: ready => {
                loadstep("stream ready");

                if(loading_splashes != null) {
                    clearInterval(loading_splashes);
                    loading_splashes = null;
                }

                var default_delay = 1000;

                if(window.innerHeight > window.innerWidth) {
                    additional_timeout = additional_timeout + 1200; // rotation mask
                }
                
                if(current_stream != null && current_stream.needsTimeout() == false) {
                    additional_timeout = 0;
                    default_delay = 0;

                    if(window.innerHeight > window.innerWidth && canRotate(game_id) || isvertical(game_id)) {
                        additional_timeout = 1000;
                    }
                }

                if(restore_id_on_load != "") {
                    additional_timeout = 3000;

                    if(sb_mode == 1) {
                        additional_timeout = 7000;
                    }
                }

                var additional_big = additional_timeout > 2000;
                //additional_timeout += 2000;

                additional_timeout = 2000;

                mylogi("Additional timeout before video: " + additional_timeout);

                animate_out("#main-image", "#main-image-pos", default_delay);
                //$("#main-image").hide();

                setTimeout(() => {
                    loading_anim = false;

                    $("#loading").animate({opacity: 0}, 750, function(){
                        $("#loading").css("display", "none");
                        
                        if(current_stream != null && current_stream.isplaystarted() == false) {
                            if(parser.getOS().name == "iOS" || parser.getBrowser().name == "Safari") {
                                $('#click-to-start').show();
                                $('#click-to-start').css({opacity: 0}).animate({opacity: 1}, 250);
                                shownClickToStart = true;
                            }
                        }
                    });

                    setTimeout(() => {
                        if(current_stream != null && current_stream.isplaystarted() == false) {
                            window.ui.showToast("Check your connection...");
                        }
                    }, 7000);

                    loadstep("finish");

                    if(sb_mode == 1 || (getCurrentGame()!= null && getCurrentGame().includes("adBla")) ) {
                        setTimeout(() => {
                            setControlsMode();
                        }, 3500);
                    }
                }, default_delay + additional_timeout);

                game_finish_load = Date.now();
                send_ev("loaded", {"id": game_id});

                if(finish_spawned == false) {
                    finish_spawned = true;
                    send_ev("load_finish", {"server": window.play_server.getServer(game_id), "load_time": ((new Date().getTime() / 1000) - secondsLoading), "fail_reason": "", "first_load": first_load});
                }

                $("#streaming-game").css("z-index", "-10000");
                setTimeout(() => {
                    $("#streaming-game").css("z-index", "auto");
                    console.log("auto");
                }, default_delay + additional_timeout);

                if(window.pay == null || window.pay == undefined) {
                    window.pay = new Payments(payment_type);
                }
                if(window.ads == null || window.ads == undefined) {
                    window.ads = new Ads(ads_type);
                }
                window.pay.sessionLoaded();

                if(restore_id_on_load != "") {
                    received_oob = false;
                    setTimeout(() => {
                        sendRestoreToGame(restore_id_on_load);
                    }, 999);
                    
                    if(restore_continue == false && false) {
                        spinnerState("loading_save", additional_timeout + default_delay);
                    }else{
                        spinnerState("loaded", additional_timeout + default_delay);
                    }
                }else{
                    spinnerState("loaded", additional_timeout + default_delay);
                }
                if(store_logs) {
                    sendToGame({}, "logging");
                }

                if(screenshot_onload && isDisableAnimations() == false) {
                    // make screenshot
                    setTimeout(() => {
                        // hide main image animated with opacity
                        $("#main-image").animate({opacity: 0}, 1000, function(){
                            $("#main-image").css("display", "none");
                            $("#main-image").css("opacity", 1);

                            $("#main-image").attr('src', $('#thumb-'+thumbPostfix(current_game) + game_id).attr('src'));
                            $("#thumb-"+thumbPostfix(current_game)+game_id).css({"filter": "blur(1px)"});
                        });
                    }, 1000);
                }

                // send untouch for all
                if(restore_continue && current_stream != null) {
                    for(var i=0; i<10; i++) {
                        current_stream._sendInputEvent("touch-end", {
                            id: i,
                            last: i == 9,
                          });
                    }
                }

                if(restore_continue == false) {
                    setTimeout(() => {
                        if(sb_mode == 1) {
                            window.ui.showLinkAccount();
                        }
                    }, additional_timeout + default_delay + 1000);
                }

                //
                let rotateAttempt = setInterval(() => {
                    if(current_stream == null) {
                        return;
                    }
                    if(!current_stream.getDimensions()) {
                        return;
                    }

                    var t1 = 10;
                    var t2 = 10;

                    if(additional_big) {
                        t1 = 10;
                        t2 = 10;
                    }

                    mylogi("!!!!", window.innerHeight > window.innerWidth, canRotate(game_id), isvertical(game_id));

                    if(window.innerHeight > window.innerWidth && canRotate(game_id) || isvertical(game_id)) {
                        // portrait
                        if( parser.getDevice().type == "smarttv" ) {
                            t2 = 1000;
                        }

                        setTimeout(function() {
                            if(current_stream != null) {
                                current_stream.rotate("portrait", true, isvertical(game_id), isvertical(game_id));
                            }
                        }, t2+t1);
                    }else{
                        setTimeout(function() {
                            if(current_stream != null) {
                                current_stream.rotate("landscape", true, isvertical(game_id), isvertical(game_id));
                            }
                        }, t2+t1);
                    }
                    clearInterval(rotateAttempt);
                }, 10);

                // try to play music
                if(parser.getBrowser().name != "Mobile Safari" && parser.getBrowser().name != "Safari"
                    && parser.getBrowser().name != "Firefox" && parser.getBrowser().name != "Firefox Reality" && current_stream != null) {
                        const audio = document.getElementById(current_stream.getAudioID());

                        let playAttempt = setInterval(() => {
                            audio.play()
                            .then(() => {
                                clearInterval(playAttempt);

                                var state = getcounter('muted', 'all');
                                if(state == 0) {
                                    if(additional_timeout > 0) {
                                        setTimeout(() => {
                                            fade_sound_in(audio);
                                        }, additional_timeout);
                                    }else{
                                        fade_sound_in(audio);
                                    }
                                }
                            })
                            .catch(error => {
                            });
                        }, 1000);
                    }else{
                        startMusicOnClick = true;
                    }
                    // load chat
                    loadChat();
                //
            },
            error: error => {
                send_ev("error", {"id": game_id, "err": error.message});

                if(error.message != "failed to create data channel") {
                    reloadPopup();
                }

                if(finish_spawned == false) {
                    finish_spawned = true;
                    send_ev("load_finish", {"server": window.play_server.getServer(game_id), "load_time": ((new Date().getTime() / 1000) - secondsLoading), "fail_reason": "webrtc: " + error.message, "first_load": first_load});
                }
            },
            fullscreen: entered => {
                if(entered) {
                    $("#fs-text").html("Exit fullscreen");
                }else{
                    $("#fs-text").html("Fullscreen");
                    if(current_stream != null) {
                        current_stream._onResize();
                    }
                }
            },
            inputevent: event => {
                newmouseevent();

                if(event.type === 'pointerdown' || event.type === 'pointerup') {
                    if(startMusicOnClick && current_stream != null) {
                        startMusicOnClick = false;
                        const audio = document.getElementById(current_stream.getAudioID());
                        audio.play()
                        .then(() => {
                            var state = getcounter('muted', 'all');
                            if(state == 0) {
                                if(additional_timeout > 0) {
                                    setTimeout(() => {
                                        fade_sound_in(audio);
                                    }, additional_timeout);
                                }else{
                                    fade_sound_in(audio);
                                }
                            }
                        })
                        .catch(error => {
                        });
                    }
                }

                if(event.type == 'pointerdown') {
                    dragstartx = event.pageX;
                    dragstarty = event.pageY;
                    ismousedown = true;

                    current_stream.startFPSIncrease();

                    /*if(current_game == "Decor") {
                        if(event.clientX > 218 && event.clientX < 872 && event.clientY > 63 && event.clientY < 129) {
                            window.pay.pay_now("coins", "m_" + Math.random(), "test_pay");
                        }
                    }
                    if(current_game == "Legions") {
                        if(event.clientX > 339 && event.clientX < 972 && event.clientY > 56 && event.clientY < 129) {
                            window.pay.pay_now("coins", "m_" + Math.random(), "test_pay");
                        }
                    }*/
                }
                if(event.type == 'pointermove') {
                    if(ismousedown && Math.abs(dragstartx - event.pageX) > 5 && Math.abs(dragstarty - event.pageY) > 5 && sb_mode != 1) {
                        setTimeout(function() {
                            if(ismousedown) {
                                document.body.style.cursor = "grab"
                            }
                        }, 300);
                        dragged = true;
                    }
                }
                if(event.type == 'pointerup') {
                    if(dragged == false) {
                        addcounter("click");
                        addclicks();
        
                        send_click();
        
                        // if left mouse button
                        if(event.button == 0) {
                            var x = event.pageX;
                            var y = event.pageY;
                            showbubble(x, y);
                        }
                    }
                    
                    document.body.style.cursor = "auto"
                    dragged = false;
                    ismousedown = false;

                    current_stream.stopFPSIncrease();
                }
            },
            messageReceived: (type, data) => {
                handleMessageReceive(type, data);
            },
            statsUpdated: (_stats) => {
                if(current_stream != null) {
                    var attr = {
                        "networkType": _stats.network.networkType,
                        "transportType": _stats.network.transportType,
                        "bandwidth": _stats.video.bandwidthMbit,
                        "fps": _stats.video.fps,
                        "decodeTime": _stats.video.decodeTime,
                        "jitter": _stats.video.jitter,
                        "packetsLost": _stats.video.packetsLost
                    };

                    if(last_stats != null) {
                        var newLostPackets = attr['packetsLost'] - last_stats['packetsLost'];
                        var bitrateNow = attr['bandwidth'] - last_stats['bandwidth'];

                        if(newLostPackets > 0 && false) { // currently lost is not freeze
                            mylogi("Lost " + newLostPackets + " packets with bitrate" + bitrateNow);

                            detectedFreeze("lost");
                        }
                    }

                    last_stats = attr;
                    //send_ev("stats", attr);

                    window.fps = _stats.video.fps

                    if(_stats.video.fps == undefined && _stats.video.bandwidthMbit > 0) {
                        window.fps = "ok"
                    }
                }
            },
            videostream: (videostream) => {
                videostream.getVideoTracks().forEach(videoTrack => {
                    videoTrack.onmute = () => {
                        detectedFreeze("event");
                    };
                    videoTrack.onunmute = () => {
                        if(wasmuted) {
                            detectedUnfreze("event");
                        }else{
                            send_ev("playing", {"seconds_loading": ((new Date().getTime() / 1000) - secondsLoading)});
                        }
                    };
                });
            }
        }
    });

    stream.connect();
    current_stream = stream;
    current_game = game_id;
    PubSub.publish("game.changed");
    last_game = current_game;

    // save last_game
    window.profile.setItem("last_game", last_game);
}

var active_sessions = [];
var saveids = [];
var game_changes = 0;
var game_loading = false;
var jumped = false;
var loading_anim = false;
var game_finish_load = 0;

var thumbsizex = 256;
var aspect = 1152 / 720.0;
var thumbsizey = thumbsizex / aspect;

export function getCurrentSaveID()
{
    var save_id = "";
    for(var i=0; i<saveids.length; i++) {
        if(saveids[i]['game_id'] == current_game) {
            save_id = saveids[i]['save_id'];
            break;
        }
    }
    if(current_game == "stage") {
        save_id = "stage/new"
    }

    return save_id;
}

export function isGameLoading() {
    return game_loading;
}

function updateThumbSize() {
    if(getPlayTag() == "squared" && current_game == special_game) {
        aspect = 1152 / 720.0;
        thumbsizey = thumbsizex / aspect;
    }else{
        aspect = 1152 / 720.0;
        thumbsizey = thumbsizex / aspect;
    }
}

var bwimageshown = false;

export function showBWImage(visible) {
    if(visible) {
        if(bwimageshown == false) {
            makescreenshot();

            var bwimage = $("#main-image").clone(false).appendTo(".animations-top");
            bwimage.show();
            bwimage.attr('id', 'bw-image');
            bwimage.css({'-webkit-filter': 'blur(5px) saturate(0%)',
                         'filter': 'blur(5px) saturate(0%)',
                         'opacity': '0'});
            bwimage.animate({opacity: 0.85}, 1500, function(){
                        });
            bwimageshown = true;
        }
    }else{
        if(bwimageshown) {
            var bwimage = $("#bw-image");
            bwimage.attr('id', 'bw-image-removing');
            bwimage.animate({opacity: 0}, 150, function() {
                bwimage.remove();
            });
            bwimageshown = false;
        }
    }
}

export function makescreenshot(save)
{
    if( parser.getDevice().type == "smarttv" ) {
        return;
    }

    if(isPlaying() && game_loading == false && document.visibilityState == "visible") {
        var canvas = document.createElement('canvas');
        updateThumbSize();

        if(window.innerHeight > window.innerWidth && canRotate(current_game) && gameSupportsRotate(current_game) || isvertical(current_game)) {
            canvas.width = thumbsizey;
            canvas.height = thumbsizex;
        }else{
            canvas.width = thumbsizex;
            canvas.height = thumbsizey;
        }

        var ctx = canvas.getContext('2d');

        var vid = null;

        var videoTags = document.getElementsByTagName('video')
        for( var i = 0; i < videoTags.length; i++ ){
            vid = videoTags.item(i);
        }
        var black = 0;

        if(vid != null) {
             if(window.innerHeight > window.innerWidth && canRotate(current_game) && gameSupportsRotate(current_game) || isvertical(current_game)) {
                var x = canvas.width / 2;
                var y = canvas.height / 2;
                var angleInRadians = 3.14/2;

                ctx.translate(x, y);
                ctx.rotate(angleInRadians);
                ctx.drawImage(vid, -canvas.height  / 2, -canvas.width / 2, canvas.height, canvas.width);
                ctx.rotate(-angleInRadians);
                ctx.translate(-x, -y);
            }else{
                ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);
            }

            try{
                $("#main-image").attr('src', canvas.toDataURL('image/jpeg'));
            }catch(error) {
                myloge("Can't do screenshot");
            }
        }else{
            save = false;

            // do screenshot from gl
            //mylogi("saving def");
            //save = true;
            //black = -3;
        }

        // check random pixels
        for(var i=0; i<3; i++) {
            var data = ctx.getImageData(Math.floor(Math.random()*144), Math.floor(Math.random()*144), 1, 1).data;
            if(data[0] == 0 && data[1] == 0 && data[2] == 0 ) {
                black++;
            }
        }
        //
        
        if(save && black != 3) {
            window.localStorage.setItem("thumb-"+thumbPostfix(current_game) + current_game, canvas.toDataURL('image/jpeg'));
            $('#thumb-'+thumbPostfix(current_game)+current_game).attr('src', $("#main-image").attr('src'));
        }
    }
}

export function switchgamewrap(game_id)
{
    if(footerShown == false && window.mobile_mode == false) {
        showFooter();
        return;
    }

    if(isChatOpened()) {
        window.Chat.close();
    }

    switchgame(game_id);
}

export var running_pwa = false;
function checkPWA() {
    window.addEventListener('DOMContentLoaded', () => {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            running_pwa = true;
        }

        if( ('standalone' in window.navigator) && (window.navigator.standalone) ) {
            running_pwa = true;
        }
    });
}
checkPWA();

function switchgame(game_id, anims=true, override_loading=false)
{
    if(game_id.includes("adBla") && fronturl.includes("play.x") == false) {
        sb_mode = 1;
    }else{
        sb_mode = 0;
    }

    last_game = game_id;

    loadstep("switching game");

    if(game_loading && override_loading == false && isFlag("l") == false) {
        return;
    }

    window.ui.hideMaintenanceWindow();

    spinnerState("loading");

    if( getcounter("tutorial", game_id) == 0 ) {
        //$("#footer-zoom").css({"display": "none"});
    }

    var support_id = window.profile.getItem("support_" + game_id, support_id)
    if(support_id != null) {
        $("#support_id").html(support_id.toUpperCase());
        $("#support-url").attr("href", support_url+"?support=" + support_id.toUpperCase());
    }else{
        $("#support_id").html("...");
    }

    jumped = false;

    addcounter("session", 1, game_id);
    if(getcounter("session", game_id) == 1) {
        send_ev("first_session", {});
    }
    if(getcounter("session", game_id) == 2) {
        send_ev("second_session", {});
    }

    session_start = new Date().getTime() / 1000;

    send_ev("game", {"id": game_id});

    $("#footer").css("pointer-events", "none");
    setTimeout(function() {
        $("#footer").css("pointer-events", "auto");
    }, 500);

    hideFooter();

    if(game_id == current_game)
    {
        hideout_force("#thumb-"+thumbPostfix(current_game) + current_game);
        return;
    }

    var current_hash = window.location.hash.substr(1);

    if(current_hash == "reset") {
        localStorage.clear();

        history.pushState("", document.title, window.location.pathname
                                                            + window.location.search);
        window.onbeforeunload = null;
        location.reload();
    }

    if(current_hash == "logs") {
        store_logs = true;
        if(push_state) {
            history.pushState({}, null, "/" + game_id + "/");
        }
        current_hash = "";
    }else{
        setTimeout(function() {
            if(current_hash.length > 0 && game_id == "stage") {
                if(push_state) {
                    history.pushState({}, null, "/" + game_id + "/#" + current_hash);
                }
            }else{
                if(push_state) {
                    history.pushState({}, null, "/" + game_id + "/");
                }
                current_hash = ""; // hack
            }
        }, 2000);
    }

    if(current_hash == "bookmark") {
        current_hash = "";
    }

    $("#footer-fs").css({'background-color': '#999'});
    $("#footer-fs2").css({'background-color': '#999'});

    $("#footer-fs").css({'pointer-events': 'none'});
    $("#footer-fs2").css({'pointer-events': 'none'});
    
    $("#footer-fs").css({'cursor': 'auto'});
    $("#footer-fs2").css({'cursor': 'auto'});

    game_loading = true;
    loading_anim = true;

    $("#loader").css("display", "none");
    setTimeout(function() {
        if(loading_anim /*&& current_stream != null*/) {
            $("#loader").css("opacity", 0);
            $("#loader").css("display", loaderEnabled()?"block":"none");
            $("#loader").animate({opacity: 1}, 250, function(){
            });
        }
    }, 1000);

    if( $("#loading").css("display") == "none" ) {
        $("#loading").css("opacity", 0);
        $("#loading").css("display", "block");
        $("#loading").animate({opacity: 1}, 250, function(){
        });
    }

    $("#loader").css("display", loaderEnabled()?"block":"none");

    game_changes++;
    // load session id
    if(current_stream != null) {
        var gamefly = current_game;
        setTimeout(function() {
            $("#thumb-"+thumbPostfix(gamefly)+gamefly).css({"visibility": "visible"});
        }, 999);

        $("#thumb-"+thumbPostfix(gamefly)+gamefly).css({"filter": "blur(1px) grayscale(100%)"});
        $("#thumb-"+thumbPostfix(gamefly)+gamefly).on("mouseenter mouseleave", function(e) {
            $(this).css({"filter": e.type === "mouseenter"?"blur(1px)":"blur(1px) grayscale(100%)"});
        });

        var canvas = document.createElement('canvas');

        updateThumbSize();

        if(window.innerHeight > window.innerWidth && canRotate(gamefly) && gameSupportsRotate(current_game) || isvertical(current_game)) {
            canvas.width = thumbsizey;
            canvas.height = thumbsizex;
        }else{
            canvas.width = thumbsizex;
            canvas.height = thumbsizey;
        }

        var ctx = canvas.getContext('2d');

        var vid = null;

        var videoTags = document.getElementsByTagName('video')
        for( var i = 0; i < videoTags.length; i++ ){
            vid = videoTags.item(i);
        }

        if(vid != null) {
            if(window.innerHeight > window.innerWidth && canRotate(current_game) && gameSupportsRotate(current_game) || isvertical(current_game)) {
                var x = canvas.width / 2;
                var y = canvas.height / 2;
                var angleInRadians = 3.14/2;

                ctx.translate(x, y);
                ctx.rotate(angleInRadians);
                ctx.drawImage(vid, -canvas.height  / 2, -canvas.width / 2, canvas.height, canvas.width);
                ctx.rotate(-angleInRadians);
                ctx.translate(-x, -y);
            }else{
                ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);
            }
        }
        
        var pos = $("#main-image-pos").offset();
        
        if(vid != null && Date.now() - game_finish_load >= 10000 && parser.getDevice().type != "smarttv" ) { // at least ten seconds after load
            $("#main-image").attr('src', canvas.toDataURL('image/jpeg'));
            window.localStorage.setItem("thumb-"+thumbPostfix(current_game) + current_game, canvas.toDataURL('image/jpeg'));
        }

    //    $("#main-image").css({position : "fixed", left: pos.left, top: pos.top});

        $("#main-image").show();

        var bottomfly = 300;
        var dev = parser.getDevice().type;
        if( dev == "mobile" || dev =="tablet" ) {
            bottomfly = 900;
        }

        var flying2 = fly('#main-image', '#thumb-'+thumbPostfix(current_game)+current_game, null, true, bottomfly);

        if(vid != null) {
            $('#thumb-'+thumbPostfix(current_game)+current_game).attr('src', $("#main-image").attr('src'));
        }
        $("#main-image").hide();

        current_stream.disconnect();

        current_game = null;
        current_stream = null;
    }

    $("#thumb-"+thumbPostfix(current_game)+game_id).off("mouseenter mouseleave");
    
    var dev = parser.getDevice().type;
    if( dev != "mobile" && dev !="tablet" && dev != "smarttv" ) {
        $("#thumb-"+thumbPostfix(current_game)+game_id).on("mouseenter mouseleave", function(e) {
            if(e.type === "mouseenter") {
                showFooter();
            }
        });
    }

    var flying = null;
    if(anims) {
        flying = fly("#thumb-"+thumbPostfix(current_game) + game_id, '#main-image', null, false);

        if(flying != null) {
            flying.css({"filter": "blur(5px)"});
        }
    }
    if(gameSupportsRotate(game_id) == false && canRotate(game_id) && isvertical(game_id) == false && isHorizontalNow()) {
        $("#main-image").addClass("rotatecenter");
        if(flying != null) {
            flying.addClass("rotatecenter");
        }
    }else{
        $("#main-image").removeClass("rotatecenter");
        if(flying != null) {
            flying.removeClass("rotatecenter");
        }
    }
    $("#thumb-"+thumbPostfix(current_game)+game_id).css({"visibility": "visible"});

    if(sb_mode != 1) {
        $("#main-image").attr('src', $('#thumb-'+thumbPostfix(current_game) + game_id).attr('src'));
        $("#thumb-"+thumbPostfix(current_game)+game_id).css({"filter": "blur(1px)"});
    }
    var sid = getsid(game_id);

    console.log("SID", sid);
    screenshot_onload = false;

    if(['556967'].indexOf(sid) > -1) {
        var srcNow = $("#main-image").attr('src');

        if(srcNow != null && srcNow.includes("f/"+sid+"-anim.webp") == false) {
            $("#main-image").attr('src', prefix + "f/"+sid+"-anim.webp");

            screenshot_onload = true;
        }
    }

    $("#streaming-game").css("z-index", "-10000");
    if( game_id != "stage" ) {
        $("#big_logo").attr('src', getlogo(game_id));
        $("#big_logo2").attr('src', getlogo(game_id));
        $("#pay_logo").attr('src', getlogo(game_id));
        $("#pay_logo2").attr('src', getlogo(game_id));

        set_favicon(get_favicon(game_id));
    }else{
        $("#big_logo").attr('src', "../f/big-0120.png");
        $("#big_logo2").attr('src', "../f/big-0120.png");
        $("#pay_logo").attr('src', "../f/big-0120.png");
        $("#pay_logo2").attr('src', "../f/big-0120.png");
    }

    document.title = getname(game_id) + " on " + sitename;

    if(anims && isDisableAnimations() == false ) {
        $("#main-image").hide();
        setTimeout(function() {
                $("#main-image").css({opacity : 0});
                $("#main-image").show();

                $("#main-image").animate({opacity: 1}, 250, function(){
                    $("#main-image").css({opacity : 1});
                });
            }, 1000);
    }else{
        $("#main-image").show();
    }

    // try to restore active session
    var restored = false;
    /*if(current_hash.length > 0) {
        for(var i=0; i<active_sessions.length; i++) {
            if(active_sessions[i]['game_id'] == game_id) {
                active_sessions.splice(i, 1);
                saveActiveSessions();
                break;
            }
        }
    }else{
        if(rejoin_session == false) {
            for(var i=0; i<active_sessions.length; i++) {
                if(active_sessions[i]['game_id'] == game_id) {
                    // retore 
                    additional_timeout = 0;
                    showFrom(active_sessions[i]['data'], game_id);
                    restored = true;
                    break;
                }
            }
        }
    }*/

    if(restored == false) {
        if(rejoin_session) {
            for(var i=0; i<active_sessions.length; i++) {
                if(active_sessions[i]['game_id'] == game_id) {
                    active_sessions.splice(i, 1);
                    saveActiveSessions();
                    break;
                }
            }
        }
        rejoin_session = false;

        var found_save_id = "";
        for(var i=0; i<saveids.length; i++) {
            if(saveids[i]['game_id'] == game_id) {
                found_save_id = saveids[i]['save_id'];
                break;
            }
        }

        // reset if hald of tutorial not completed
        if(getcounter("click", game_id) <= 25 || get_favicon(game_id) == "" || (game_id == "Island" && getcounter("tutorial", game_id) == 0)) {
            /*found_save_id = "";
            // remove it
            for(var i=0; i<active_sessions.length; i++) {
                if(active_sessions[i]['game_id'] == game_id) {
                    active_sessions.splice(i, 1);
                    saveActiveSessions();
                    break;
                }
            }
            for(var i=0; i<saveids.length; i++) {
                if(saveids[i]['game_id'] == game_id) {
                    saveids.splice(i, 1);
                    saveActiveSessions();
                    break;
                }
            }*/
        }

        var srvs = window.play_server.getServer(game_id);
        /*if( srvs.includes("plays") ) {
            found_save_id = "";
        }
        if( srvs.includes("playu") ) {
            found_save_id = "";
        }*/
        
        if( found_save_id == "" && region_route != "stage" ) {
            first_load = 1;
            // vertical
            $.get( window.play_server.getServer(game_id) + "api/play/"+game_id+"/" + getPlayTag() + "/" + getProfileID() + "/", function( data ) {
                if(data["status"] == "fail") {
                    if("routing" in data && data['routing'] != "") {
                        send_ev("routing", {});

                        //$("#big_logo").addClass("breath");
                        window.play_server.routeServer(game_id, data['routing']);

                        setTimeout(function() {
                            switchgame(game_id, false, true);
                        }, 10);
                        return;
                    }else{
                        send_ev("no_sessions", {});

                        $("#big_logo").addClass("breath");

                        if('queue' in data) {
                            $("#queue").html("Position in queue: " + (data['queue'] + 1));
                            $("#queue").css("display", "block");
                        }

                        setTimeout(function() {
                            switchgame(game_id, false, true);
                        }, 5000);
                        return;
                    }
                }
                $("#queue").css("display", "none");
                $("#big_logo").removeClass("breath");

                let session_id = data["session"]["session_id"];
                window.play_server.playing(window.play_server.getServer(game_id), game_id, session_id);

                session_times[game_id] = {'time': data['session']['session_time'] + window.profile.getSessionTimeToday(game_id), 'updated': new Date().getTime() / 1000};
                active_sessions.push({"game_id": game_id, "data": data});
                saveids.push({"game_id": game_id, "save_id": data['session']['save_id']});

                mixpanel.register({
                    "game": game_id,
                    "saveid": data['session']['save_id']
                });

                saveActiveSessions();

                additional_timeout = 0;
                showFrom(data, game_id);

                send_ev("load_start", {"server": window.play_server.getServer(game_id), "load_time": 0, "fail_reason": "", "first_load": first_load});
            }, ).fail(function(xhr, status, error) {
                if(xhr.status == 404) {
                    gotoMaintenance();
                }else{
                    reloadPopup();

                    if(finish_spawned == false) {
                        finish_spawned = true;
                        send_ev("load_finish", {"server": window.play_server.getServer(game_id), "load_time": ((new Date().getTime() / 1000) - secondsLoading), "fail_reason": "fail", "first_load": first_load});
                    }
                }
            });
        }else{
            first_load = 0;

            var save_part = found_save_id.substring(found_save_id.indexOf("/") + 1);
            var url = url = window.play_server.getServer(game_id) + "api/continuefast/"+game_id+"/fast/"+save_part + "/" + getProfileID() + "/";

            if(region_route == "dev") {
                window.play_server.setTestServer(window.play_server.getServer(game_id));
            }

            var onready = function( data ) {
                if(data["status"] == "fail") {
                    if("routing" in data && data['routing'] != "") {
                        send_ev("routing", {});

                        //$("#big_logo").addClass("breath");
                        window.play_server.routeServer(game_id, data['routing']);

                        setTimeout(function() {
                            switchgame(game_id, false, true);
                        }, 10);
                        return;
                    }else{
                        send_ev("no_sessions", {});

                        $("#big_logo").addClass("breath");

                        if('queue' in data) {
                            $("#queue").html("Position in queue: " + (data['queue'] + 1));
                            $("#queue").css("display", "block");
                        }

                        setTimeout(function() {
                            switchgame(game_id, false, true);
                        }, 5000);
                        return;
                    }
                }
                $("#queue").css("display", "none");
                $("#big_logo").removeClass("breath");

                let session_id = data["session"]["session_id"];
                window.play_server.playing(window.play_server.getServer(game_id), game_id, session_id);

                active_sessions.push({"game_id": game_id, "data": data});

                saveActiveSessions();

                additional_timeout = 6000;

                var restore_id_on_load = "";
                var restore_continue = false;
                if(data.hasOwnProperty("restore")) {
                    restore_id_on_load = data['restore'];
                    additional_timeout = 3500;
                }else{
                    if(sb_mode == 1) {
                        additional_timeout += 5000;
                    }
                }
                if(data.hasOwnProperty("existing")) {
                    restore_continue = true;
                    additional_timeout = 0;
                }
                if(sb_mode == 1) {
                    //restore_id_on_load = "";
                    //restore_continue = false;
                }

                session_times[game_id] = session_times[game_id] = {'time': data['session']['session_time'] + window.profile.getSessionTimeToday(game_id), 'updated': new Date().getTime() / 1000};

                showFrom(data, game_id, restore_id_on_load, restore_continue);

                send_ev("load_start", {"server": window.play_server.getServer(game_id), "load_time": 0, "fail_reason": "", "first_load": first_load});
            };
            
            if(region_route == "stage") {
                // make call to get proper server and check if it was not 404
                $.get(serverurl_login + "api/test/"+game_id+"/" + getProfileID() + "/", function( data ) {
                    // parse json from data
                    try{
                        var result = JSON.parse(data);

                        if(result['status'] == 'ok') {
                            if(found_save_id != "") {
                                url = result['host'] + "api/test/" + result['aid'] + "/" + found_save_id + "/" + getProfileID() + "/";
                            }else{
                                url = result['host'] + "api/test/" + result['aid'] + "/" + game_id + "/new" + "/" + getProfileID() + "/";
                            }

                            window.play_server.setTestServer(result['host']);

                            $.get(url, onready).fail(function() {
                                reloadPopup();
                                
                                if(finish_spawned == false) {
                                    finish_spawned = true;
                                    send_ev("load_finish", {"server": window.play_server.getServer(game_id), "load_time": ((new Date().getTime() / 1000) - secondsLoading), "fail_reason": "fail", "first_load": first_load});
                                }
                            });
                        }else{
                            // go to maintenance window
                            gotoMaintenance();
                        }
                    }catch(e) {
                        // go to maintenance window
                        gotoMaintenance();
                    }
                }).fail(function() {
                    // go to maintenance window
                    gotoMaintenance();
                });
            }else{
                $.get(url, onready).fail(function(xhr, status, error) {
                    if(xhr.status == 404) {
                        // go to maintenance window
                        gotoMaintenance();
                    }else{
                        reloadPopup();
                        
                        if(finish_spawned == false) {
                            finish_spawned = true;
                            send_ev("load_finish", {"server": window.play_server.getServer(game_id), "load_time": ((new Date().getTime() / 1000) - secondsLoading), "fail_reason": "fail", "first_load": first_load});
                        }
                    }
                });
            }
        }
    }
}

function saveActiveSessions()
{
    window.profile.setItems({"sessions": JSON.stringify(active_sessions),
                                "saveids": JSON.stringify(saveids)});
}

function loadActiveSessions()
{
    var sessions = window.profile.getItem("sessions");
    if(sessions) {
        try{
            var parsed = JSON.parse(sessions);
            active_sessions = parsed;
        }catch(e)
        {
        }
    }

    var saves = window.profile.getItem("saveids");
    if(saves) {
        try{
            var parsed = JSON.parse(saves);
            saveids = parsed;
        }catch(e)
        {
        }
    }
}

function get_passphrase() 
{
    var match = document.cookie.match(new RegExp('(^| )' + "best_pwd" + '=([^;]+)'));
    if (match) {
        return match[2];
    }
    return "";
}


////////////////////////////////////////////////////////////////////////
// main start logic
export function getname(game_id)
{
    for(var i=0; i<names.length; i++)
    {
        if(names[i]['id'] == game_id) {
            return names[i]['name'];
        }
    }
    return game_id;
}

function getlogo(game_id)
{
    for(var i=0; i<names.length; i++)
    {
        if(names[i]['id'] == game_id) {
            return names[i]['logo'];
        }
    }
    return prefix + "f/big-0120.png";
}

export function getCurrentLogo() {
    return getlogo(last_game);
}

export function getCurrentGameName() {
    return getname(last_game);
}

function getsid(game_id)
{
    for(var i=0; i<names.length; i++)
    {
        if(names[i]['id'] == game_id) {
            return names[i]['sid'];
        }
    }
    return "";

}

function get_favicon(game_id)
{
    for(var i=0; i<names.length; i++)
    {
        if(names[i]['id'] == game_id) {
            return names[i]['favicon'];
        }
    }
    return "";
}

var game_load_started = false;
// check which sessions can be restored
function startloading()
{
    game_load_started = true;
    active_sessions = [];
    saveActiveSessions();

    // window.profile.setItem("last_game", last_game);
    // if there is token in url then use last_game
    if(window.login.findGetParameter("token")) {
        if(window.profile.getItem("last_game") != null) {
            last_game = window.profile.getItem("last_game");
            game_start = last_game;

            console.log("Using previous game", last_game);
        }
    }

    switchgame(game_start, false);
}

export function animate_bottom(elem_name) {
    animated_bottoms.push(elem_name);
}

function do_bottom_animation(delay)
{
    var dev = parser.getDevice().type;
    if( dev == "mobile" ) {
        return; // do not jump on right
    }

    setTimeout(() => {
        var canJump = true;

        if(getcounter("click") < 25) {
            canJump = false;
        }
        if(game_loading) {
            canJump = false;
        }
        if(jumped) {
            canJump = false;
        }
        /*if(ismousedown) {
            canJump = false;
        }*/

        if(canJump) {
            for(var i=0; i<animated_bottoms.length; i++) {
                let k = i;
                setTimeout(() => {
                    $(animated_bottoms[k]).addClass("bottombounce");
                }, 100*i);
            }

            setTimeout(() => {
                for(var i=0; i<animated_bottoms.length; i++) {
                    $(animated_bottoms[i]).removeClass("bottombounce");
                }
            }, 1000 + 70*animated_bottoms.length);

            jumped = true;
        }

        if( game_changes <= 1 ) {
            do_bottom_animation(10000);
        }else{
            do_bottom_animation(30000);
        }
   }, delay);
}

//////////////////////////////////////////////////////////////////////////////////////////////////
// fingerprint
var visibilityTimer;

function visibilityListener() {
    switch(document.visibilityState) {
        case "hidden":
            visibilityTimer = setTimeout(function() {
                reloadPopup();
            }, 1000*60*2); // 2 mins

            // mute sound
            if(current_stream != null) {
                const audio = document.getElementById(current_stream.getAudioID());
                if(audio != null) {
                    audio.pause();
                }
            }
        break;
        case "visible":
            clearTimeout(visibilityTimer);
            if( game_load_started == false && window.profile != null && window.profile != undefined  && window.profile.isReady() ) {
                if(/bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent) == false) {
                    startloading();
                }
            }
            if(reload_on_activate) {
                if(reload_triggered == false) {
                    reloadWithToast();
                }
                reload_triggered = true;
            }
            
            if(parser.getOS().name == "iOS" || parser.getBrowser().name == "Safari") {
                startMusicOnClick = true;
            }else{
                if(current_stream != null) {
                    const audio = document.getElementById(current_stream.getAudioID());

                    if(audio != null) {
                        let playAttempt = setInterval(() => {
                            audio.volume = 0;
                            audio.play()
                            .then(() => {
                                var state = getcounter('muted', 'all');
                                if(state == 0) {
                                    clearInterval(playAttempt);
                                    fade_sound_in(audio);
                                }
                            })
                            .catch(error => {
                            });
                        }, 1000);
                    }
                }
            }
        break;
    }
}

document.addEventListener("visibilitychange", visibilityListener);

// close multiple tabs
var myopentime = new Date().getTime();
export var clicksnow = 0;
export function addclicks() {
    clicksnow++;
}

export function checkOnlyTab()
{
    if(window.profile.getItem("lastopen") == undefined || window.profile.getItem("lastopen") == 'undefined') {
        window.profile.setItem("lastopen", myopentime);
    }

    if(window.profile.getItem("lastopen") > myopentime) {
        var reason = window.profile.getItem("restore");

        if(reason == "link") {
            var device = window.profile.getItem("restore_device");
            if(device == undefined || device == null || device == "") {
                device = "mobile;"
            }

            gotoIdleState("other tab link: " + device + " " + myopentime + " " + window.profile.getItem("lastopen"), true, device);
        }else{
            gotoIdleState("other tab: " + myopentime + " " + window.profile.getItem("lastopen"));
        }
    }

    checkIdleState();
}

export function addcounter(id, amount=1, game=null)
{
    if(game == null) {
        game = current_game;
    }
    window.profile.setItem(game+"_cnt"+id, getcounter(id) + amount, false);
}

export function setcounter(id, amount=1, game=null, flush=false)
{
    if(game == null) {
        game = current_game;
    }
    window.profile.setItem(game+"_cnt"+id, amount, flush);

    mylog(game+"_cnt"+id, amount);
}

export function getcounter(id, game=null)
{
    if(game == null) {
        game = current_game;
    }
    var ret = window.profile.getItem(game+"_cnt"+id);

    if(ret == null) {
        ret = 0;
    }else{
        ret = parseInt(ret);
    }

    return ret;
}

export function addcounter_local(id, amount=1, game=null)
{
    if(game == null) {
        game = current_game;
    }
    localStorage.setItem(game+"_cnt"+id, getcounter_local(id) + amount, false);
}

export function setcounter_local(id, amount=1, game=null)
{
    if(game == null) {
        game = current_game;
    }
    localStorage.setItem(game+"_cnt"+id, amount, false);
}

export function getcounter_local(id, game=null)
{
    if(game == null) {
        game = current_game;
    }
    var ret = localStorage.getItem(game+"_cnt"+id);

    if(ret == null) {
        ret = 0;
    }else{
        ret = parseInt(ret);
    }

    return ret;
}

export function extendPlay(sku)
{
    window.pay.pay_now(sku, "extend_" + Math.random(), "extend_play");
}

export function extendedPlay(billing) {
    window.pay.consumeInapp(billing["payment_id"], () => {
        window.onbeforeunload = null;
        window.location.reload();
    });
}

export function starterGot(billing) {
    window.pay.consumeInapp(billing["payment_id"], () => {
        
    });
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function sendToGame(obj, name="message", json=undefined)
{
    mylogi(obj, JSON.stringify(obj));
    if(name == "message") {
        sendData2( JSON.stringify(obj) );
    }else{
        if(name == "restore") {
            mylogi("Converting restore to event");
            sendData2( JSON.stringify({"eventType": "restore", "id": json}) );
        }
        if(name == "logging") {
            sendData2( JSON.stringify({"eventType": "start_logs"}));
        }
    }
    if( current_stream != null ) {
        if(json == undefined) {
            var send_data = JSON.stringify(obj);
            current_stream._sendControlMessageQueue("action::custom", {name: name, args: [send_data]});
        }else{
            current_stream._sendControlMessageQueue("action::custom", {name: name, args: [json]});
        }
    }
}

// idle state
var isinidle = false;
var lastmouseevent = new Date().getTime() / 1000;
export function newmouseevent()
{
    lastmouseevent = new Date().getTime() / 1000;
    if(isinidle && idle_migration == false) {
        if(reload_triggered == false) {
            reloadWithToast();
        }
        reload_triggered = true;
    }
}

export function backbutton()
{
    if(current_stream != null) {
        current_stream._sendInputEvent('key', {
            code: 41,
            pressed: false
        });
    }
}

export function keyboardbutton()
{
    window.ui.toggleKeyboard();
}

function reloadWithToast() {
    window.ui.showToast("Reconnecting...");

    $('#click-to-start').hide();

    setTimeout(() => {
        window.onbeforeunload = null;
        window.location.reload();
    }, 2000);
}

function checkIdleState()
{
    var delay_mins = 2;

    if(window.pay != null && window.pay != undefined && window.pay.isPaying()) {
        delay_mins = 10;
    }
    
    if(new Date().getTime() / 1000 - lastmouseevent > delay_mins*60 && document.visibilityState != "hidden") {
        gotoIdleState("timeout " + (lastmouseevent - new Date().getTime() / 1000));
    }
}

var startedPlay = false;

var globalMouse = function(e) {
    newmouseevent();

    try{
        if(startedPlay == false && (current_stream != null && current_stream.isplaystarted() == false) && shownClickToStart) {
            if( e.type == "mouseup" || e.type== 'pointerup' ) {
                const video = document.getElementById(current_stream.getVideoID());
                if(video != null) {
                    video.play();
                    startedPlay = true;
                }
            }
        }
    }catch(e) {
    }

    if((e.type == "mouseup" || e.type== 'pointerup') && current_stream != null) {
        const video = document.getElementById(current_stream.getVideoID());
        if(e.target != video) {
            var ne = {
                clientX: e.clientX,
                clientY: e.clientY,
                pointerId: 1,
                pointerType: 'mouse',
                type: 'pointerup',
                button: e.button,
                movementX: e.movementX,
                movementY: e.movementY,
                
                pageX: e.pageX,
                pageY: e.pageY,
                preventDefault : preventDefault  => {

                }
            }
            current_stream._onPointerEvent(ne);
        }
        console.log(e.target.id);
        if(e.target.id != undefined && (e.target.id.startsWith('stream-video') || e.target.id == "main-image" || e.target.id == "streaming-game")) {
            hideFooter();
            if(window.Chat != null) {
                window.Chat.removeChatFocus();
            }
        }
    }

    if(e.type == "mousedown" || e.type== 'pointerdown') {
        if(e.pageX < 100 && e.pageY < 100) {
            holded_long = 0;

            if(timeout_id != -1) {
                clearTimeout(timeout_id);
            }
            if(timeout_id2 != -1) {
                clearTimeout(timeout_id2);
            }
            timeout_id2 = setTimeout(() => {
                holded_long = 1;
            }, 1000);
            timeout_id = setTimeout(() => {
                holded_long = 2;
            }, 2500);
        }

        window.onbeforeunload = function()
        {
            return 'Are you sure you want to exit the game?';
        };
    }

    if(e.type == "mouseup" || e.type== 'pointerup') {
        if(e.pageX < 50 && e.pageY < 50 && holded_long == 2) {
            reportLogs();
            holded_long = 0;
        }
        if(e.pageX < 50 && e.pageY < 50 && holded_long == 1) {
            window.ui.showKeyboard();
            holded_long = 0;
        }
        if(timeout_id != -1) {
            clearTimeout(timeout_id);
        }
        if(timeout_id2 != -1) {
            clearTimeout(timeout_id2);
        }
        timeout_id = -1;
        timeout_id2 = -1;
    }
}

var globalMouse2 = function(e) {
    if(isChatOpened()) {
        return;
    }
    if(window.linking === true) {
        return;
    }

    if(current_stream != null && fullscreendone == false && game_loading == false) {
        current_stream._requestFullscreen();
        fullscreendone = true;
        e.preventDefault();
    }
}

function block_new_elems()
{
    if(parser.getBrowser().name == "Mobile Safari") {
        const element = document.querySelector('*');

        //element.addEventListener('touchstart', (e) => {
        //    e.preventDefault();
        //});
    }
}

function globalResize(e) {
    if( parser.getBrowser().name == "Mobile Safari" ) {
        if( window.innerHeight != document.documentElement.clientHeight ) {
            // bar hidden
            hideFooter();
            $("#footerarrow2").css("display", "none");
        }else{
            // bar shown
            $("#footerarrow2").css("display", "block");
        }
    }

    checkVerticalBar();

    if(current_stream != null && canRotate(current_game)) {
        const dim = current_stream.getDimensions();
        if (dim) {
            if(window.innerWidth > window.innerHeight) {
                // landscape
                if( current_stream.getCurrentOrientation() != "landscape" ) {
                    current_stream.rotate("landscape", true, isvertical(current_game));
                }
            }else{
                // portrait
                if( current_stream.getCurrentOrientation() != "portrait" ) {
                    current_stream.rotate("portrait", true,  isvertical(current_game));
                }
            }
        }
    }

    setTimeout(() => {
        if(gameSupportsRotate(current_game) == false && canRotate(current_game) && isvertical(current_game) == false && isHorizontalNow()) {
            if( $("#main-image").hasClass("rotatecenter") == false ) {
                $("#main-image").addClass("rotatecenter");
            }
            if(animating_out != null) {
                if(animating_out.hasClass("rotatecenter") == false) {
                    animating_out.addClass("rotatecenter");
                }
            }
            mylogi("added classes");
        }else{
            if( $("#main-image").hasClass("rotatecenter") == true ) {
                $("#main-image").removeClass("rotatecenter");
            }
            if(animating_out != null) {
                if(animating_out.hasClass("rotatecenter") == true) {
                    animating_out.removeClass("rotatecenter");
                }
            }

            mylogi("removed classes");
        }
    }, 10);
}

async function generateManifest()
{
    const manifest_data = await fetch(manifest_url, {
        method: "GET"
    });

    try{
        const manifestJSON = await manifest_data.json();

        manifestJSON.start_url = fronturl + manifestJSON.start_url + "&restore_pwa=" + window.profile.getProfileID();

        for(var i=0; i<manifestJSON.icons.length; i++) {
            manifestJSON.icons[i].src = fronturl + prefix + "f/" + manifestJSON.icons[i].src;
        }

        mylogi(manifestJSON);

        const stringManifest = JSON.stringify(manifestJSON);
        const blob = new Blob([stringManifest], {type: 'application/json'});
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector('#manifest-pwa').setAttribute('href', manifestURL);
    }catch(e) {
        myloge("Cant generate manifest");
    }
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function beforexit() 
{
    if(current_stream != null) {
        current_stream.disconnect();
        await sleep(50);
    }
}

var chat_loaded = false;
async function loadChat() {
    console.log("Loading chat", chat_url, chat_loaded);

    if(chat_url != null) {
        if(chat_loaded == false) {
            window.Chat = await import('./chat.js');
            chat_loaded = true;
        }
    }else{
        // no chat
        if( isFlag('login') ) {
            window.ui.showLoginFooter();
        }
    }
}

function checkVerticalBar() {
    var dev = parser.getDevice().type;
    if( dev == "mobile" ) {
        if( window.innerHeight < window.innerWidth ) {
            // do right bar
            $('.maindiv').css('bottom', '0px');
            $('.maindiv').css('left', '32px');
            $('.mainimg').css('height', '100%');
            $('.mainimg').css('width', 'calc(100% - 33px)');
            $('.mainimg').css('left', '32px');
            $('#footer').hide();
            $('.footerleftbar').show();
        }else{
            // restore bottom bar
            $('.maindiv').css('bottom', '32px');
            $('.maindiv').css('left', '0px');
            $('.mainimg').css('height', 'calc(100% - 33px)');
            $('.mainimg').css('width', 'calc(100%)');
            $('.mainimg').css('left', '0px');
            $('#footer').show();
            $('.footerleftbar').hide();
        }
    }else{
        $('.footerleftbar').hide();
    }
}

$(document).ready(function() {
    loadstep("document ready");

    PubSub.publish('document.ready');

    animate_now();
    window.onbeforeunload = function(event)
    {
        if(current_stream != null) {
            beforexit();
        }
        return undefined;
    }

    window.oncontextmenu = function() { return false; }

    this.addEventListener('mousemove', globalMouse);
    this.addEventListener('mouseup', globalMouse);
    this.addEventListener('pointerup', globalMouse);
    this.addEventListener('mousedown', globalMouse);
    this.addEventListener('pointerdown', globalMouse);

    $(document).bind("contextmenu",function(e){
        return false;
    });

   /* $("#buy-btn-pack").on("click", () => {
        if(window.pay != null && window.pay != undefined) {
            window.pay.pay_now("com.starterpack", "starter_" + Math.random(), "starter_pack");
        }
    });
    $("#buy-btn-pack").css("display", "inline-block");

    $("#buy-btn-pack2").on("click", () => {
        if(window.pay != null && window.pay != undefined) {
            window.pay.pay_now("com.starterpack", "starter_" + Math.random(), "starter_pack");
        }
    });
    $("#buy-btn-pack2").css("display", "inline-block");*/
    
    var dev = parser.getDevice().type;
    if( dev == "mobile" || dev == "tablet" ) {
        this.addEventListener('mouseup', globalMouse2);
        this.addEventListener('pointerup', globalMouse2);

        $("#footergames").remove();

        $('.maindiv').css('right', '0px');
        $('.centering-div').css('transform', 'translate(0, 0)');
        $('.mainimgoffset').css('width', 'calc(100%)');
        firstoffsetx = 0;
        
        $(".mobile-hide").css("display", "none");
        $(".rounded-back").css("margin-right", "5px");
        $("#footer-zoom").css("margin-right", "5px");
        $("#footer-fs").css("margin-right", "0px");
        $(".footerright").css("margin-right", "2px");

        $(".hoverborder").css("margin-right", "0px");
        $(".hoverborder").css("padding-right", "0px");

        $('[id^=thumb-]').removeClass("thumb_hover");

        if(parser.getOS().name == "iOS") {
            $("#download-btn-ios").css("display", "inline-block");
            $("#download-btn-ios2").css("display", "inline-block");

            setTimeout(function() {
                window.scrollTo(0, 0);
            }, 5000);
        }else{
            $("#download-btn-gp").css("display", "inline-block");
            $("#download-btn-gp2").css("display", "inline-block");
        }

        window.mobile_mode = true;
    }else{
        $("#download-btn").css("display", "inline-block");
        $("#download-btn2").css("display", "inline-block");

        $("#footer-kb").hide();
        $("#footer-kb2").hide();

        //$("#buy-btn-pack").css("margin-right", "25px");

        $("#footergames2").remove();

        window.mobile_mode = false;

        if(sb_mode == 1) {
            this.addEventListener('mouseup', globalMouse2);
            this.addEventListener('pointerup', globalMouse2);
        }else{
            fullscreendone = true;
        }
    }

    checkVerticalBar();

    if( parser.getBrowser().name == "Mobile Safari" ) {
        fullscreendone = true;
    }
        
    window.addEventListener('resize', globalResize);

    block_new_elems();

    // load payments asyncly
    window.play_server = new PlayServer();
    window.profile = new Profile(serverurl_login, game_start);
    window.profile.fetch(() => {
        loadstep("profile fetched");
        
        sendCachedEvents();

        if(localStorage.getItem("restore_id")) {

            var dev_type = parser.getDevice().type;

            if(dev_type == undefined || dev_type == null) {
                dev_type = "pc";
            }

            //window.profile.setItem("restore", "link", false);
            //window.profile.setItem("restore_device", dev_type, false);
            window.profile.setItems({"restore": "link", "restore_device": dev_type}, false);

            localStorage.removeItem("restore_id");
        }else{
            window.profile.setItem("restore", "no", false);
        }

        window.profile.setItem("lastopen", myopentime);
        loadActiveSessions();

        generateManifest();
        
        var state = getcounter('muted', 'all');
        if(state == 1) {
            $("#sound-tx").html("Off");
            $("#sound-img").attr('src', prefix+"f/volume-no.png");
            
            $("#sound-img2").attr('src', prefix+"f/volume-no.png");
        }

        if(document.visibilityState == "visible" && game_load_started == false) {    
            if(/bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent) == false) {                    
                startloading();
            }
        }

        if(sb_mode == 1) {
            var bookmark = getcounter('bookmark');

            if(parser.getDevice().type != "smarttv" && bookmark == 0) {
                setTimeout(() => {
                    showBookmark();
                }, 4*60*1000);
            }
        }
    });

    if(ops == false || dev == "mobile" || dev =="tablet") {
        if(parser.getBrowser().name == "Mobile Safari" || parser.getBrowser().name == "Safari" || parser.getOS().name == "iOS") {
            $("#footer-fs").hide();
            $("#footer-fs2").hide();
        }

        if(dev == "mobile" || dev =="tablet") {
            $(".rounded-button").removeClass("rounded-button-hover");
            $(".rounded-back").removeClass("rounded-back-hover");
        }

        var dev = parser.getDevice().type;
        if( dev != "mobile" && dev !="tablet" && dev != "smarttv" && false) {
            $("#footerback").on("mouseenter mouseleave", function(e) {
                if(e.type === "mouseenter") {
                    showFooter();
                }else{
                    hideFooter();
                }
            });
            $("#outergames").on("mouseenter mouseleave", function(e) {
                if(e.type === "mouseenter") {
                    showFooter();
                }else{
                    hideFooter();
                }
            });
            $("#payment2").hide();
        }else{
            $("#footerarrow2").on("mousedown", function(e) {
                if(footerShown) {
                    hideFooter();
                }else{
                    showFooter();
                }
            });

            $("#footerarrow3").on("mousedown", function(e) {
                if(footerShown) {
                    hideFooter();
                }else{
                    showFooter();
                }
            });

            if( dev != "mobile" && dev !="tablet" && dev != "smarttv" ) {
                $("#footerarrow2").on("mouseenter mouseleave", function(e) {
                    if(e.type === "mouseenter") {
                        showFooter();
                    }
                });

                
                $(".thumb").on("mouseenter mouseleave", function(e) {
                    if(e.type === "mouseenter") {
                        showFooter();
                    }
                });
            }

            if( dev == "mobile" || dev == "tablet") {
                $("#footergames").css({"margin-left": "50vw", "margin-right": "0px"});
            }
            $("#payment1").hide();
        }

        this.addEventListener('mousedown', function(e) {
            if(e.target.id != undefined && (e.target.id.startsWith('stream-video') || e.target.id == "main-image")) {
                hideFooter();
                if(window.Chat) {
                    window.Chat.close();
                }
            }
        });
    }

    // prevent back
    if( parser.getOS().name == "Android" || parser.getOS().name == "Android-x86" ) {
        history.pushState({}, null, "/");

        window.addEventListener('popstate', (e) => {
            e.preventDefault();

            if(push_state && current_game != "" && current_game != undefined) {
                history.pushState({}, null, "/" + current_game + "/");
            }else{
                history.pushState({}, null, "/");
            }
        });
    }

    window.ui = new UI();
    window.login = new Login();
});

function gotoMaintenance() {
    game_loading = false;
    region_route = "auto";

    window.ui.showMaintenanceWindow();

    if(current_stream != null && document.fullscreenElement != null) {
        current_stream.exitFullscreen();
    }

    hideFooter();
    hideBookmark(false);
        
    if(isChatOpened()) {
        if(window.Chat) {
            window.Chat.close();
        }
    }
    
    spinnerState("lock");
    $('#click-to-start').hide();
    $("#loading").css("opacity", 1);
    $("#loader").css("display", "none");
    $("#main-image").css({filter: 'blur(5px)'});
    $("#main-image").show();

    if(current_stream != null) {
        current_stream.disconnect();
    }

    $("#loading").css("display", "none");
    $("#snooze").css("display", "none");

    current_game = null;
    current_stream = null;
}

var idle_migration = false;
export function gotoIdleState(reason, migration=false, device="")
{
    mylogi("gotoIdleState", reason, migration, device);

    if(overtime) {
        return;
    }
    if(isinidle == false || migration) {
        if(migration == false) {
            mylogi("Requesting");
            window.profile.versionNow(2692956647);
        }
        else{
            mylogi("not requesting");
        }

        if(current_stream != null && document.fullscreenElement != null) {
            current_stream.exitFullscreen();
        }

        isinidle = true;

        if(isPlaying()) {
            makescreenshot(true);
        }

        hideFooter();
        hideBookmark(false);
        
        if(isChatOpened()) {
            window.Chat.close();
        }
        window.ui.blockWindows();

        spinnerState("lock");
        $('#click-to-start').hide();
        $("#loading").css("opacity", 1);
        $("#loader").css("display", "none");
        $("#main-image").css({filter: 'blur(5px)'});
        $("#main-image").show();

        send_ev("gone_idle", {'reason': reason});
        if(current_stream != null) {
            current_stream.disconnect();
        }

        if(migration) {
            window.ui.showMigrating(device);

            $("#loading").css("display", "none");
            $("#snooze").css("display", "none");
        }else{
            $("#loading").css("display", "block");
            $("#snooze").css("display", "block");
        }
        idle_migration = migration;

        current_game = null;
        current_stream = null;

        if(isFlag("chat")) {
            $("#chat_container").css("filter", "blur(10px)");
        }

        PubSub.publish("system.block");
    }
}

function isPlaying()
{
    return reload_on_activate == false && isinidle == false;
}
//

setInterval(function() {
    if(isPlaying()) {
        makescreenshot(true);
    }
}, 60000);

var showBlockAt = 0;
export function signupShown(reset=false)
{
    if(reset == false) {
        showBlockAt = getPlayingTime() + 30;
    }else{
        showBlockAt = 0;
    }
}

function showLoginIfNo()
{
    if(window.login.isLoggedIn() == false) {
        window.ui.showLoginPrompt();
    }
}

export function getTodayPlayTime() {
    return session_times[current_game]['time'] + (new Date().getTime() / 1000 - session_times[current_game]['updated']);
}

export function getTodayPlaytimeLeft() {
    if(isFlag('demo') && showBlockAt != 0) {
        return showBlockAt - getPlayingTime();
    }
    if(window.profile.isExtendedPlaytime(current_game)) {
        return 60*60; // infinite
    }
    return window.profile.getSessionTimeTodayMax(current_game);
}

function updatePlayTime()
{
    if(current_game != null && current_stream != null) {
        if(current_game in session_times) {
            var seconds = getTodayPlayTime();
            var pt_left = getTodayPlaytimeLeft();
            if( pt_left <= 0 ) {
                showOvertime();
            }

            if(pt_left <= 5*60) {
                window.ui.showOvertimeTimer();
            }

            if(isFlag('demo')) {
                if( getPlayingTime() == 30 ) {
                    if(window.login.isLoggedIn()) {
                        signupShown();
                    }else{
                        window.ui.runOnFree(showLoginIfNo);
                    }
                }
            }else{
                if(Math.floor(seconds) == 300) {
                    window.ui.runOnFree(showLoginIfNo);
                }
            }
        }
    }
}

setInterval(function() {
    if(isPlaying()) {
        updatePlayTime();
    }
}, 1000);

export function showbubble(x, y) {
    if(sb_mode == 1) {
        return ;
    }
    var id = "pointerbubble" + Math.random();
    var $div = $("<div>", {'id': id, "class": "pointerbubble"});
    $("#animation-top-div").append($div);

    $div.css({'left': x + 'px', 'top': y + "px"});
    $div.css({'width': '0px', 'height': '0px'});

    var size = 20;
    var dev = parser.getDevice().type;
    if( dev == "mobile" || dev =="tablet" ) {
        size = 10;
    }

    setTimeout(function() {
        $div.css({'width': '0px', 'height': '0px'})
        .animate({'width': size + 'px', 'height': size + 'px'}, 250, function(){
            $div.animate({'width': size*1.25 + 'px', 'height': size*1.25 + 'px', 'opacity': 0}, 250, function(){
                $div.remove();
            });
        });
    }, 100);
}

export function fullscreen()
{
    if(current_stream != null && game_loading == false && isPlaying()) {
        if(document.fullscreenElement != null) {
            current_stream.exitFullscreen();
        }else{
            current_stream._requestFullscreen();
        }
    }
}

function showNotifications()
{
    if(window.pay != null && window.pay != undefined && window.pay.isPaying()) {
        setTimeout(() => {
            showNotifications();
        }, 10000);
        return;
    }

    /*window.ui.runOnFree(() => {
        OneSignal.push(function() {
            OneSignal.showSlidedownPrompt();
        });
    });*/
}

export function showBookmark()
{
    if(running_pwa || isPlaying() == false) {
        return;
    }
    
    if(window.pay != null && window.pay != undefined && window.pay.isPaying() ||
       (current_stream != null && document.fullscreenElement != null)) {
        setTimeout(() => {
            showBookmark();
        }, 10000);
        return;
    }
    var dev = parser.getDevice().type;
    if( dev != "mobile" && dev !="tablet" ) {
        window.ui.showBookmark();

        setcounter('bookmark', 1, null, true);

        if(current_game != null) {
            if(push_state) {
                history.pushState({}, null, "/" + current_game + "/#bookmark");
            }else{
                history.pushState({}, null, "/#bookmark");
            }
        }
    }
}

export function hideBookmark(show_notifs=true)
{
    if(current_game != null) {
        if(push_state) {
            history.pushState({}, null, "/" + current_game + "/");
        }else{
            history.pushState({}, null, "/");
        }
    }

    if(show_notifs) {
        setTimeout(() => {
            showNotifications();
        }, 10000);
    }
}

var footerShown = false;

function showFooter()
{
    if(ops) {
        $('#footer').css({transform: 'translate(0, -100px)'});
        $("#footerarrow").css({transform: "translate(8px, 2px) scaleY(-1)"});
        $("#footerarrow2").css({transform: "translate(-50%, 0px) scaleY(-1)"});
    }else{
        if(multigame_js > 1 && window.mobile_mode == false) {
            $('#footer').css({transform: 'translate(0, calc(-100%))'});
        }else{
            $('#footer').css({transform: 'translate(0, -90px)'});
        }

        $("#footerarrow2").css({transform: "translate(-50%, 0px) scaleY(-1)"});
    }

    $("#footerarrow3").css({transform: "rotate(-90deg)"});
    footerShown = true;

    if(window.mobile_mode) {
        if(isChatOpened()) {
            window.Chat.close();
        }
    }

    $('#footerleftbar').css({transform: 'translate(0px, 0px)'});
}

export function hideFooter()
{
    $("#footerarrow").css({transform: "translate(8px, 2px)"});
    $("#footerarrow2").css({transform: "translate(-50%, 0px)"});

    $('#footer').css({transform: 'translate(0, -33px)'});
    $("#footerarrow3").css({transform: "rotate(90deg)"});
    footerShown = false;

    
    $('#footerleftbar').css({transform: 'translate(-120px, 0px)'});
}

function createDeeplink()
{
    var data = {
    };

    var sessions = window.profile.getItem("sessions");
    if(sessions) {
        try{
            var parsed = JSON.parse(sessions);
            data['sessions'] = parsed;
        }catch(e)
        {
        }
    }

    var saves = window.profile.getItem("saveids");
    if(saves) {
        try{
            var parsed = JSON.parse(saves);
            data['saveids'] = parsed;
        }catch(e)
        {
        }
    }

    return JSON.stringify(data);
}

export function zoom(val)
{
    if(current_stream != null) {
        var amount = 1;
        var dev = parser.getDevice().type;
        if(parser.getOS().name == "iOS" || parser.getOS().name == "Android" || parser.getOS().name == "Android-x86" || dev == "mobile" || dev =="tablet") {
            //val = val * 10;
            amount = 4;
        }

        for(let i=0; i<amount; i++) {
            current_stream._onMouseWheel({'deltaX': 0, 'deltaY': val});
        }

        /////////////////////////////
        // tmp hack
        current_stream._sendInputEvent('key', {
            code: 29,
            pressed: true
        });
        current_stream._sendInputEvent('key', {
            code: 29,
            pressed: false
        });

        ////
        //window.pay.pay_now("coins", "m_" + Math.random(), "test_pay");
    }
}

var last_sound_volume = 1;
var playing_now = true;

export function sound_playing(playing)
{
    if(playing_now == playing) {
        return;
    }

    if(playing == false) {
        if(current_stream != null) {
            const audio = document.getElementById(current_stream.getAudioID());
            if(audio != null) {
                last_sound_volume = audio.volume;
                audio.volume = 0;
            }
        }
    }else{
        if(current_stream != null) {
            const audio = document.getElementById(current_stream.getAudioID());
            if(audio != null) {
                audio.volume = last_sound_volume;
            }
        }
    }

    playing_now = playing;
}

export function sound()
{
    var state = getcounter('muted', 'all');

    if(state == 0) {
        // mute
        setcounter('muted', 1, 'all', true);

        if(current_stream != null) {
            const audio = document.getElementById(current_stream.getAudioID());
            if(audio != null) {
                audio.volume = 0;

                if(parser.getOS().name == "iOS" || parser.getBrowser().name == "Safari") {
                    audio.muted = true;
                }
            }
        }
        $("#sound-tx").html("Off");
        $("#sound-img").attr('src', prefix+"f/volume-no.png");
        $("#sound-img2").attr('src', prefix+"f/volume-no.png");
    }else{
        // unmute
        setcounter('muted', 0, 'all', true);

        if(current_stream != null) {
            const audio = document.getElementById(current_stream.getAudioID());
            audio.volume = 1;

            if(parser.getOS().name == "iOS" || parser.getBrowser().name == "Safari") {
                audio.muted = false;
            }
        }
        $("#sound-tx").html("On");
        $("#sound-img").attr('src', prefix+"f/volume.png");
        $("#sound-img2").attr('src', prefix+"f/volume.png");
    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////
// Loading spinner
var finished_prg = false;

function setLoadingProgress(progress, next) {
    var prg_int = Math.floor(progress);

    $("#load_text").text("LOADING: " + prg_int + "%");
    $("#load_prg").animate({width: Math.floor(20 + 324 * progress/100.0) + "px"}, next);
}

function movePrg(prg, delta) {
    if(finished_prg) {
        delta = 10.0;
    }
    prg = prg + delta;
    if(prg >= 100) {
        prg = 100;
    }
    setLoadingProgress(prg, 25);
    
    if( prg < 100 ) {
        setTimeout(() => {
            movePrg(prg, delta * 0.99);
        }, 50);
    }else{
        $("#load-spinner").hide();
    }
}

function finishedPrg() {
    finished_prg = true;
}

var spinner_shown = false;
var spinner_state = "";
var loaded_timeout_passed = false;

function spinnerState(state, timeout=0)
{
    mylogi(state, spinner_state, timeout);

    if(spinner_state == "lock" ||
        spinner_state == "loaded" ||
        (spinner_state == "auth" && state !="loading_save")) {
        return;
    }

    if(state == "loading") {
        setTimeout(() => {
            if(spinner_state == "loading" || (spinner_state == "loaded" && loaded_timeout_passed == false)) {
                setSpinner(true);
            }
            mylogi();
        }, loaderEnabled()?3000:500);
    } else if(state == "loaded") {
        setTimeout(() => {
            loaded_timeout_passed = true;
            setSpinner(false);
        }, timeout);
    } else if(state == "auth") {
        if(spinner_state == "loading_save") {
            setTimeout(() => {
                if(spinner_state == "auth") {
                    setSpinner(false);
                }
            }, 2000);
        }else{
            return;
        }
    } else if(state == "lock") {
        setSpinner(false);
    } else if(state == "loading_save") {
        setSpinner(true);

        setTimeout(() => {
            if(spinner_state == "loading_save") {
                setSpinner(false);
            }
        }, 10000);
    }

    spinner_state = state;
}

export function setSpinner(shown)
{
    if(shown && lock_spinner == false) {
        if(spinner_shown == false) {
            finished_prg = false;

            $("#load-spinner").show();

            movePrg(0, 0.7);
        }        
        spinner_shown = true;
    }else{        
        spinner_shown = false;
        finishedPrg();
    }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////

export function reloadPopup() {
    mylogi("reloadPopup");

    if(overtime) {
        return;
    }

    mylogi("Requesting");
    window.profile.versionNow(2692956647);

    send_ev("popup_timeout", {});
    if(current_stream != null) {
        current_stream.disconnect();
    }

    current_game = null;
    current_stream = null;

    PubSub.publish("system.block");

    hideFooter();
    hideBookmark(false);
    
    if(isChatOpened()) {
        window.Chat.close();
    }

    $("#loading").css("opacity", 1);
    $("#loader").css("display", "none");
    $("#loading").css("display", "block");
    //$("#snooze").css("display", "block");
    $("#main-image").css({filter: 'blur(5px)'});
    $("#main-image").show();
    spinnerState("lock");

    if(document.visibilityState == "hidden") {
        reload_on_activate = true;
    }else{
        if((new Date().getTime() / 1000) - getcounter_local("refresh_time") > 60) {
            setcounter_local("refresh", 0);
        }

        var currentreload = getcounter_local("refresh");
        mylogi("Reload now", currentreload);
        if(currentreload < 3) {
            setTimeout(function() {
                addcounter_local("refresh", 1);
                setcounter_local("refresh_time", (new Date().getTime() / 1000));
                window.onbeforeunload = null;
                location.reload();
            }, 500); // 0.5 seconds
        }else{
            $("#loading").css("opacity", 1);
            $("#loader").css("display", "none");
            $("#loading").css("display", "block");
            $("#snooze-error").css("display", "block");
            $("#main-image").css({filter: 'blur(5px)'});
            $("#main-image").show();
            
            setTimeout(function() {
                $("#loader").css("opacity", 0);
                $("#loader").css("display", "none");
            }, 3500);
        }
    }
}

export function isChatFocused() {
    if(window.Chat) {
        return window.Chat.isChatFocused;
    }
    return false;
}

export function isChatOpened() {
    if(window.ui.isNoWindow() == false) {
        return true;
    }
    if(window.inputNeeded) {
        return window.inputNeeded;
    }
    if(window.Chat) {
        return window.Chat.chatOpened;
    }
    return false;
}

export function showOvertime() {
    overtime = true;

    hideFooter();
    hideBookmark(false);
    
    if(isChatOpened()) {
        window.Chat.close();
    }

    spinnerState("lock");
    $('#click-to-start').hide();
    $("#loader").css("display", "none");
    $("#loading").css("display", "none");
    $("#snooze").css("display", "none");
    $("#main-image").css({filter: 'blur(5px)'});
    $("#main-image").show();

    send_ev("show_overtime", {});
    if(current_stream != null) {
        current_stream.disconnect();
    }

    window.ui.hideWindows();

    current_stream = null;
    window.ui.showOvertime();

    loadChat();

    if(window.pay == null || window.pay == undefined) {
        window.pay = new Payments(payment_type);
    }

    /*if(isFlag("chat")) {
        $("#chat_container").css("filter", "blur(10px)");
    }*/

    PubSub.publish("system.block");
}

export function handleWindowError(window_id, error) {
    send_ev("error_window", {"window": window_id, "err": error.message});
    reloadPopup();
    
    if(finish_spawned == false) {
        finish_spawned = true;
        send_ev("load_finish", {"server": window.play_server.getServer(game_id), "load_time": ((new Date().getTime() / 1000) - secondsLoading), "fail_reason": "error: " + error.message, "first_load": first_load});
    }
}

export function fail() {
    setTimeout(() => {
        var a = null;
        a['test'] = 10;
        alert(a['test']);
    }, 2000);
}